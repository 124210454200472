<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="row">
        <div class="col-4">
          <nav>
            <!-- <ul>
              <li>
                <router-link to="/contact-person" >
                  Hubungi Kami
                </router-link>
              </li>
              <li>|</li>
              <li>
                <router-link to="/contact-person" >
                  Struktur Organisasi
                </router-link>
              </li>
            </ul> -->
          </nav>
          <a href="https://www.instagram.com/pt.tef" target="_blank" ><i class="fab fa-instagram mr-4" style='font-size:48px;color:white'></i></a>
          <a href="https://www.youtube.com/@TokkiEngineeringandFabrication" target="_blank"><i class="fab fa-youtube mr-4" style='font-size:48px;color:white'></i></a>
          <a href="https://www.linkedin.com/company/pt-tef/" target="_blank" ><i class="fab fa-linkedin-in mr-4" style='font-size:48px;color:white'></i></a>
          <a href="https://vt.tiktok.com/ZS6rRGdc1/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -20px; " height="42" width="38" viewBox="0 0 448 512">
              <path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
            </svg>
          </a>
        </div>
        <div class="col-4 text-center">
          <!-- Visitor Counter <br> <router-link to="/detail-counter-visit" ><n-button type="info" size="sm">{{ totalVisitor }}</n-button></router-link> -->
        </div>
        <div class="col-4">
          &copy;  PT. TOKKI ENGINEERING AND FABRICATION
        </div>
      </div>
    </div>
    <a href="https://api.whatsapp.com/send?phone=628119103075 &text=Hallo PT. Tokki Engineering and Fabrication" class="float my-float text-white" target="_blank">
        <i class="fab fa-whatsapp" style="margin-top: 15px;"></i>
    </a>
  </footer>
</template>
<script>
import { FormGroupInput, Button, Modal } from '@/components';
import Api from '@/helpers/api';
import visitorCounter from '@/services/visitorCounter.service';

export default {
  components: {
    [Button.name]: Button,
  },
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      totalVisitor : '',
    };
  },
  mounted(){
    this.getVisitorCounter()
  },
  methods: {
    getVisitorCounter(param){
      let context = this;               
      Api(context, visitorCounter.getVisitorCounter()).onSuccess(function(response) {    
          context.totalVisitor = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.totalVisitor = '';
          }
      }).onFinish(function() {  
      })
      .call()
    },
  }
};
</script>
<style>
    .float{
        position:fixed;
        width:60px;
        height:60px;
        bottom:40px;
        right:40px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        font-size:30px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
    }

    .my-float{
        margin-top:16px;
    }
</style>
