import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import DataLaporan from './pages/DataLaporan.vue';
import FormLaporan from './pages/FormLaporan.vue';
import ContactPerson from './pages/ContactPerson.vue';
import DetailCounterVisit from './pages/DetailCounterVisit.vue';
import Account from './pages/Account.vue';

import LandingPage from './pages/LandingPage.vue';
import AboutUs from './pages/AboutUs.vue';
import Certification from './pages/Certification.vue';
import Facilities from './pages/Facilities.vue';
import Product from './pages/Product.vue';
import DetailProductVessel from './pages/DetailProductVessel.vue';
import DetailProductHe from './pages/DetailProductHe.vue';
import DetailProductPiping from './pages/DetailProductPiping.vue';
import DetailProductTower from './pages/DetailProductTower.vue';
import DetailProductMufle from './pages/DetailProductMufle.vue';
import DetailProductRadiant from './pages/DetailProductRadiant.vue';
import DetailProductOther from './pages/DetailProductOther.vue';
import DetailProductService from './pages/DetailProductService.vue';
import DetailServicePatching from './pages/DetailServicePatching.vue';
import DetailServicePiping from './pages/DetailServicePiping.vue';
import DetailServiceReshell from './pages/DetailServiceReshell.vue';
import DetailServiceRetubing from './pages/DetailServiceRetubing.vue';
import Blog from './pages/Blog.vue';
import DetailBlog from './pages/DetailBlog.vue';
import ContactUs from './pages/ContactUs.vue';
import Login from './pages/Login.vue';

import Landing from './pages/Landing.vue';
import Profile from './pages/Profile.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

const vurRouter = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      components: { default: LandingPage, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/about-us',
      name: 'AboutUs',
      components: { default: AboutUs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/certification',
      name: 'Certification',
      components: { default: Certification, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/facilities',
      name: 'Facilities',
      components: { default: Facilities, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/product',
      name: 'Product',
      components: { default: Product, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-vessel',
      name: 'DetailProductVessel',
      components: { default: DetailProductVessel, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-he',
      name: 'DetailProductHe',
      components: { default: DetailProductHe, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-piping',
      name: 'DetailProductPiping',
      components: { default: DetailProductPiping, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-tower',
      name: 'DetailProductTower',
      components: { default: DetailProductTower, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-mufle',
      name: 'DetailProductMufle',
      components: { default: DetailProductMufle, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-radiant',
      name: 'DetailProductRadiant',
      components: { default: DetailProductRadiant, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-other',
      name: 'DetailProductOther',
      components: { default: DetailProductOther, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-other',
      name: 'DetailProductOther',
      components: { default: DetailProductOther, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-product-service',
      name: 'DetailProductService',
      components: { default: DetailProductService, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-service-patching',
      name: 'DetailServicePatching',
      components: { default: DetailServicePatching, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-service-piping',
      name: 'DetailServicePiping',
      components: { default: DetailServicePiping, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-service-shell',
      name: 'DetailServiceReshell',
      components: { default: DetailServiceReshell, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-service-retubing',
      name: 'DetailServiceRetubing',
      components: { default: DetailServiceRetubing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/blog',
      name: 'Blog',
      components: { default: Blog, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-blog',
      name: 'DetailBlog',
      components: { default: DetailBlog, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      components: { default: ContactUs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },




    {
      path: '/data-laporan',
      name: 'DataLaporan',
      components: { default: DataLaporan, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/form-laporan/:typeForm',
      name: 'FormLaporan',
      components: { default: FormLaporan, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/contact-person',
      name: 'ContactPerson',
      components: { default: ContactPerson, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/detail-counter-visit',
      name: 'DetailCounterVisit',
      components: { default: DetailCounterVisit, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/account',
      name: 'Account',
      components: { default: Account, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },


    {
      path: '/index-app',
      name: 'IndexApp',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/landing',
      name: 'landing',
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/profile',
      name: 'profile',
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

vurRouter.beforeEach((to, from, next) => {
  const publicPages = ['login', 'LandingPage', 'IndexApp', 'ContactPerson','landing','AboutUs','Certification','Facilities','ContactUs','Product','DetailProductVessel','DetailProductHe','DetailProductPiping','DetailProductTower','DetailProductMufle','DetailProductRadiant','DetailProductOther','DetailProductService','DetailServicePatching','DetailServicePiping','DetailServiceReshell','DetailServiceRetubing','Blog','DetailBlog'];
  const authRequired = !publicPages.includes(to.name);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));  

  if (authRequired && !authenticated) {
      // alert('Session Kamu Habis Ayo Login Lagi !!!')
      return next({
          name: 'LandingPage',
          query: {redirect: to.fullPath}
      });
  }

  if (authenticated) {
      const auth = JSON.parse(authenticated);
      if (to.name == 'login') {
          return next({
              name: 'DataLaporan'
          });
      }
      if (to.name != 'Relogin') {
          if (auth.expired) {
            alert('Session Kamu Habis Ayo Login Lagi !!!')
            localStorage.removeItem('token');
            localStorage.setItem('authenticated', false)  
            return next({
                name: 'login'
            });
          }
      }
  }
  next();
})


export default vurRouter;
