<template>
  <div>
    <div class="page-header clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/welding.png')"
      >
      </parallax>
      <div class="container">
        <h2 class="title mt-5">Detail Product / Services</h2>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 section section-tabs">
            <div class="container">
              <h5 class="text-dark font-weight-bold mb-5">Service list</h5>
              <router-link to="/detail-product-service">
                <div class="card p-3 cards">
                  Welding Overlay
                </div>
              </router-link>
              <router-link to="/detail-service-patching">
                <div class="card p-3 cards">
                  Patching
                </div>
              </router-link>
              <router-link to="/detail-service-piping">
                <div class="card p-3 cards">
                  Piping
                </div>
              </router-link>
              <router-link to="/detail-service-shell">
                <div class="card p-3 cards">
                  Re-Shell
                </div>
              </router-link>
              <router-link to="/detail-service-retubing">
                <div class="card p-3 cards">
                  Re-Tubing
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-9">
            <img
              src="img/detail_product/service/Piping/02_Onsite Piping.jpeg"
              class="rounded animated fadeInDown"
              height="18%"
            />
            <h2 class="mt-4 text-dark"><span style="color: green;" class="font-weight-bold">Piping</span></h2>
            <!-- <h5>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h5> -->

            <h4 class="text-dark font-weight-bold">Comprehensive Piping Fabrication and Welding Services</h4>
            We specialize in the fabrication, welding, and installation of piping systems, tailored to meet your specific requirements. Our services offer flexibility, with fabrication performed either in our workshop or directly at your site, depending on your project needs. <br><br>

            From precision welding to seamless installation, our experienced team ensures the highest quality standards at every stage of the process. By offering on-site services, we minimize downtime and enhance efficiency, delivering reliable solutions that integrate seamlessly into your operations. <br><br>

            <div class="col-md-10 ml-auto mr-auto mt-5 mb-5">
              <div class="row collections">
                <div class="col-md-6">
                  <img src="img/detail_product/service/Piping/01_Shopfloor Welding.jpeg" class="img-raised" />
                  <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">Shopfloor Welding</h5>
                  <!-- <img src="img/detail_product/service/Piping/02_Onsite Piping.jpeg" alt="" class="img-raised" /> -->
                  <!-- <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">02_Onsite Piping</h5> -->
                  <img src="img/detail_product/service/Piping/03_Piping Installation.jpeg" alt="" class="img-raised" />
                  <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">Piping Installation</h5>
                  <img src="img/detail_product/service/Piping/08_Support Installation.jpeg" alt="" class="img-raised" />
                  <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">08_Support Installation</h5>
                </div>
                <div class="col-md-6 mb-5">
                  <img src="img/detail_product/service/Piping/04_Piping Installation.jpeg" class="img-raised" />
                  <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">Piping Installation</h5>
                  <img src="img/detail_product/service/Piping/05_Support Installation.jpeg" alt="" class="img-raised" />
                  <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">Support Installation</h5>
                  <img src="img/detail_product/service/Piping/06_Support Installation.jpeg" alt="" class="img-raised" />
                  <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">Support Installation</h5>
                  <img src="img/detail_product/service/Piping/07_Support Instllattion.jpeg" alt="" class="img-raised" />
                  <h5 style="margin-top: -60px;" class="text-white ml-3 mb-5">Support Instllattion</h5>
                </div>
              </div>
            </div>
            
          </div>
        </div>

      </div>
    </div>

    <!-- MOTO TOKKI  -->
    <div class="page-header clear-filter" filter-color="purple">
      <div class="parallax">
        <div class="container" style="">
          <h1 class="text-white text-left pt-5 font-weight-bold text-center">START <span style="font-size: 20px; color: yellow;"> with TOKKI </span></h1>
          <div class="row pt-5">
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">Main office & factory  :</span></h6>
              Kawasan Industri Jababeka 1
              Jl.Jababeka XIIB Blok W26
              Cikarang-Bekasi, Jawa Barat Indonesia Kode Pos 17530
              Telp: +62-21 893 6733 (Hunting) <br><br>

              Phone : +62 - 21 8936733 (Hunting)<br>
              Fax   : +62 - 21 8936738<br>
              Email : agus.setiyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">2nd office & factory</span></h6>
              Kawasan Industri Krakatau 1,
              Jl. Australia I Kav. C1/2, Kel. Warnasari, Kec. Citangkil
              Kota Cilegon, Banten - Indonesia 42443 <br><br>
              
              Phone : +62 - 254 8317244<br>
              Fax   : +62 - 254 8317244<br>
              Email : sutriyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">3rd Branch office</span></h6>
              Green Garden, Blok A-4 No. 01 Jl. Dr. Wahidin Sudirohusodo Kel. Dahanrejo, Kec. Kebomas Gresik, Jawa Timur, Indonesia 61124 <br><br>
            
              Phone : +62 - 31 39935914<br>
              Fax   : +62 - 31 39935914<br>
              Email : sales@tef.co.id<br>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  methods: {
    toDetailProduct(param){
      this.$router.push('/detail-product/'+param)
    }
  }
};
</script>
<style>
   .animated {
     
      padding-top:0px;
      margin-bottom:0px;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
   }
   
   @-webkit-keyframes fadeInDown {
      0% {
         opacity: 0;
         -webkit-transform: translateY(-20px);
      }
      100% {
         opacity: 1;
         -webkit-transform: translateY(0);
      }
   }
   
   @keyframes fadeInDown {
      0% {
         opacity: 0;
         transform: translateY(-50px);
      }
      100% {
         opacity: 1;
         transform: translateY(0);
      }
   }
   
   .fadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
   }


  .cards:hover{
    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(1.1);
    cursor: pointer;;
  }
</style>
